@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import 'custom';
@import 'colors';
@import 'sizes';
@import 'mixins';

* {
  font-family: 'Jost', sans-serif;
}

::-moz-selection {
  background: $utopia-dark;
  color: $utopia-light-gray;
}

::selection {
  background: $utopia-dark;
  color: $utopia-light-gray;
}

/* width */
::-webkit-scrollbar {
  width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $utopia-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $utopia-gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $utopia-medium-gray;
}

button.loginButtonCustom {
  width: 215px !important;
}

svg.loginButtonCustom {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4) !important;
  border: 0 !important;
  border-color: #ff0000 !important;
}

p {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.row {
  margin: 0;
}

.form-control:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem rgba(90, 98, 104, 0.25);
}

.form-row {
  margin-right: 0;
  margin-left: 0;
}

/*
** Components
*/

/* utopia-header */

.utopia-header {
  text-align: center;
  display: none;
  background-color: $utopia-light-gray;
  height: $header-height;
  width: 100%;
}

.utopia-header div {
  height: $header-height;
}

.utopia-header .col > img.fluid-logo {
  height: 100%;
}

.utopia-header .col > img.horizontal-logo {
  height: 70%;
}

/* utopia-nav */
.utopia-nav {
  min-height: $header-height;
}

.utopia-nav .navbar-brand {
  margin-right: 0;
}

.utopia-nav img {
  width: 75%;
  height: auto;
}

.utopia-nav .utopia-dropdown-menu .dropdown-menu {
  background-color: transparent;
  border: none;
}

.utopia-nav .utopia-dropdown-menu .dropdown-menu .dropdown-item:hover,
.utopia-nav .utopia-dropdown-menu .dropdown-menu .dropdown-item:focus {
  background-color: $utopia-dark;
  color: $utopia-white;
}

.utopia-nav .utopia-dropdown-menu .dropdown-menu .dropdown-item:active,
.utopia-nav .utopia-dropdown-menu .dropdown-menu .dropdown-item.active {
  background-color: $utopia-dark;
}

.utopia-nav .utopia-dropdown-menu .utopia-dropdown-submenu {
  margin-left: 1.5rem;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background: none;
}

/* utopia-content */
.utopia-content {
  // overflow-x: auto;
  height: $content-height;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.utopia-content > .container-fluid {
  height: 100%;
  padding: 0;
}

.container-fluid > .row > div[class*='col'] {
  padding: 0;
}

.col > .container-fluid {
  padding: 0;
}

/*
.utopia-content .utopia-logo-column {
    background-color: $utopia-gray;
    text-align: center;
}
*/

/*
.utopia-content .utopia-logo-column>img {
    margin: 1rem auto;
    width: 30%;
    height: auto;
    transition: 0.3s;
}
*/
/* utopia-menu */

.utopia-menu {
  margin-right: 0.125rem;
  padding: 0;
  background-color: $utopia-medium-gray;
  position: relative;
}

.utopia-menu {
  height: $content-height;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.utopia-menu::-webkit-scrollbar {
  display: none;
}

.utopia-menu-link {
  @include menu_link($utopia-black, $utopia-medium-gray);
  text-decoration: none;
  border-bottom: 0.1rem solid $utopia-white;
  font-size: 1.45rem;
  font-weight: 900;
  transition: color 0.15s;
  padding: 0.85rem 1rem 0.85rem 1.25rem;
}

.utopia-menu-link.active {
  @include menu_link($utopia-color, $utopia-light-gray);
}

.utopia-menu-link:hover {
  @include menu_link($utopia-color, $utopia-white);
}

.utopia-menu .utopia-menu-link:first-of-type {
  border-top: 0.1rem solid $utopia-white;
}

.utopia-menu .accordion .utopia-menu-card {
  border-bottom: 1px solid $utopia-white;
}

.utopia-menu-card {
  border: none;
  background-color: $utopia-medium-gray;
}

.utopia-menu-card.show {
  background-color: $utopia-gray;
}

.utopia-menu-card:hover {
  background-color: $utopia-white;
}

.utopia-menu-card a {
  text-decoration: none;
}

.utopia-menu-card.loquista:hover
  .utopia-menu-card-header
  .utopia-menu-card-title {
  @include section_color('loquista');
}

.utopia-menu-card.sesame:hover
  .utopia-menu-card-header
  .utopia-menu-card-title {
  @include section_color('sesame');
}

.utopia-menu-card.vision:hover
  .utopia-menu-card-header
  .utopia-menu-card-title {
  @include section_color('vision');
}

.utopia-menu-card.theia:hover .utopia-menu-card-header .utopia-menu-card-title {
  @include section_color('theia');
}

.utopia-menu-card.brain:hover .utopia-menu-card-header .utopia-menu-card-title {
  @include section_color('brain');
}

.utopia-menu-card.minerva:hover
  .utopia-menu-card-header
  .utopia-menu-card-title {
  @include section_color('minerva');
}

.utopia-menu-card.flow:hover .utopia-menu-card-header .utopia-menu-card-title {
  @include section_color('flow');
}

.utopia-menu-card .card-header {
  border: none;
  border-bottom: 0.1rem solid $utopia-white;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
}

.utopia-menu-card .card-header-submenu {
  padding: 0.75rem 1.25rem;
}

.utopia-menu-card .card-body {
  border-bottom: 0.1rem solid $utopia-white;
}

.utopia-menu-card .card-header:hover,
.utopia-menu-card.show .card-header:hover {
  background-color: $utopia-white;
}

.utopia-menu-card.show .card-header {
  background-color: $utopia-gray;
}

///////////
.utopia-menu-card.show .card-body {
  display: block;
}

.utopia-menu-card .card-body {
  display: none;
}
////////////

.utopia-menu-card .utopia-arrow {
  position: absolute;
  right: 1.25rem;
  top: 0.65rem;
  font-size: 2rem;
  color: $utopia-dark;
}

.utopia-menu-card .utopia-arrow-up {
  display: none;
}

.utopia-menu-card .utopia-arrow-down {
  display: block;
}

.utopia-menu-card .card-body {
  padding: 0;
}

.utopia-menu-card .card-body .list-group-item {
  padding: 0.2rem 1rem;
  border: none;
  border-bottom: 0.1rem solid $utopia-white;
  background-color: $utopia-gray;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: -1px;
  color: $utopia-black;
}

.utopia-menu-card .card-body .list-group-item:hover {
  background-color: $utopia-white;
}

.utopia-menu-card.loquista .card-body .list-group-item:hover {
  @include section_color('loquista');
}

.utopia-menu-card.sesame .card-body .list-group-item:hover {
  @include section_color('sesame');
}

.utopia-menu-card.vision .card-body .list-group-item:hover {
  @include section_color('vision');
}

.utopia-menu-card.theia .card-body .list-group-item:hover {
  @include section_color('theia');
}

.utopia-menu-card.brain .card-body .list-group-item:hover {
  @include section_color('brain');
}

.utopia-menu-card.minerva .card-body .list-group-item:hover {
  @include section_color('minerva');
}

.utopia-menu-card.flow .card-body .list-group-item:hover {
  @include section_color('flow');
}

.utopia-menu-card .card-body .list-group-item a {
  text-decoration: none;
  color: $utopia-black;
  transition: color 0.15s;
}

.utopia-menu-card .card-body a .list-group-item.selected {
  transition: color 0.15s;
  background-color: $utopia-light-gray;
}

.utopia-menu-card .card-body .loquista.list-group-item.selected {
  @include section_color('loquista');
}

.utopia-menu-card .card-body .sesame.list-group-item.selected {
  @include section_color('sesame');
}

.utopia-menu-card .card-body .vision.list-group-item.selected {
  @include section_color('vision');
}

.utopia-menu-card .card-body .theia.list-group-item.selected {
  @include section_color('theia');
}

.utopia-menu-card .card-body .brain.list-group-item.selected {
  @include section_color('brain');
}

.utopia-menu-card .card-body .minerva.list-group-item.selected {
  @include section_color('minerva');
}

.utopia-menu-card .card-body .flow.list-group-item.selected {
  @include section_color('flow');
}

.utopia-menu-card-header {
  font-size: 1.25rem;
  font-weight: 700;
  color: $utopia-black;
  transition: color 0.15s;
}

.utopia-menu-card-header.selected {
  background-color: $utopia-light-gray;
}

.utopia-menu-card-header:hover {
  color: $utopia-dark;
  text-decoration: none;
  background-color: $utopia-white;
}

.utopia-menu-card.show .utopia-arrow-down {
  display: none;
}

.utopia-menu-card.show .utopia-arrow-up {
  display: block;
}

.utopia-menu-card.show.loquista .utopia-arrow-up,
.utopia-menu-card.show.loquista .utopia-menu-card-header {
  @include section_color('loquista');
}

.utopia-menu-card.show.sesame .utopia-arrow-up,
.utopia-menu-card.show.sesame .utopia-menu-card-header {
  @include section_color('sesame');
}

.utopia-menu-card.show.vision .utopia-arrow-up,
.utopia-menu-card.show.vision .utopia-menu-card-header {
  @include section_color('vision');
}

.utopia-menu-card.show.theia .utopia-arrow-up,
.utopia-menu-card.show.theia .utopia-menu-card-header {
  @include section_color('theia');
}

.utopia-menu-card.show.brain .utopia-arrow-up,
.utopia-menu-card.show.brain .utopia-menu-card-header {
  @include section_color('brain');
}

.utopia-menu-card.show.minerva .utopia-arrow-up,
.utopia-menu-card.show.minerva .utopia-menu-card-header {
  @include section_color('minerva');
}

.utopia-menu-card.show.flow .utopia-arrow-up,
.utopia-menu-card.show.flow .utopia-menu-card-header {
  @include section_color('flow');
}

.utopia-menu-card-description {
  color: $utopia-dark-gray;
  font-size: 1.15rem;
  font-weight: 500;
}

/* utopia-main */
.utopia-main {
  min-height: $content-mobile-height;
  position: relative;
  padding: 0;
}

/* utopia-footer */
.utopia-footer {
  background-color: $utopia-light-gray;
  padding: 1rem;
}

.utopia-footer div {
  height: 100%;
}

.utopia-footer .col {
  text-align: center;
}

.utopia-footer .col > p {
  font-size: 0.8rem;
  font-weight: 600;
}

/*
** Pages
*/
.utopia-home-title {
  font-weight: 700;
  font-size: 1.75rem;
}

.utopia-home-subtitle {
  color: $utopia-dark-gray;
  font-weight: 600;
  margin-bottom: 0.1rem;
  font-size: 1.4rem;
}

.utopia-home-subtitle.loquista {
  @include section_color('loquista');
}

.utopia-home-subtitle.sesame {
  @include section_color('sesame');
}

.utopia-home-subtitle.vision {
  @include section_color('vision');
}

.utopia-home-subtitle.theia {
  @include section_color('theia');
}

.utopia-home-subtitle.brain {
  @include section_color('brain');
}

.utopia-home-subtitle.minerva {
  @include section_color('minerva');
}

.utopia-home-subtitle.flow {
  @include section_color('flow');
}

.utopia-home-description {
  font-weight: 500;
}

.utopia-home-image-wrap {
  text-align: center;
}

.utopia-home-features {
  font-weight: 500;
}

.utopia-home-features > li::before {
  content: '· ';
}

.utopia-products-solutions > li::before {
  content: '· ';
  font-weight: 600;
}

.utopia-products-links .card-img-top {
  width: auto;
}

.utopia-products-links:hover {
  text-decoration: none;
}

.utopia-products-cards {
  height: 100%;
  transition: background-color 0.3s;
}

.utopia-products-cards:hover {
  background-color: $utopia-medium-gray !important;
}

.utopia-products-cards.card .card-text {
  transition: color 0.15s;
}

.utopia-products-cards.card:hover .card-text {
  color: $utopia-black;
}

#utopia-landing-page-logo {
}

/*
#utopia-landing-page-logo.utopia-landing-page-panel img {
    margin: 1rem auto;
    height: auto;
    width: 30%;
    transition: 0.4s;
}

#utopia-landing-page-logo.utopia-landing-page-panel.shrunk img {
    width: 12%;
    transition:  0.4s;
}
*/

.section-landing-page .title .section-link {
  text-decoration: none;
  width: 30%;
  font-size: 1.4rem;
  font-weight: 700;
}

.section-landing-page .title.loquista .section-link {
  @include section_color('loquista');
}

.section-landing-page .title.sesame .section-link {
  @include section_color('sesame');
}

.section-landing-page .title.vision .section-link {
  @include section_color('vision');
}

.section-landing-page .title.theia .section-link {
  @include section_color('theia');
}

.section-landing-page .title.brain .section-link {
  @include section_color('brain');
}

.section-landing-page .title.minerva .section-link {
  @include section_color('minerva');
}

.section-landing-page .title.flow .section-link {
  @include section_color('flow');
}

.section-landing-page .description {
  width: 70%;
}

.section-landing-page .section-landing-page-cell {
  border: none;
}

.section-landing-page .section-landing-page-row {
  border-top: 1px solid $utopia-medium-gray;
}

.section-landing-page .section-landing-page-row:last-of-type {
  border-bottom: 1px solid $utopia-medium-gray;
}

/* carousel */

.utopia-carousel-title {
  cursor: pointer;
  transition: color 0.2s;
}

.utopia-carousel-title:hover {
  color: $loquista-color;
}

/* chat */
.utopia-chat-container {
  // min-height: 28rem;
  // max-height: 28rem;
  // overflow-y: scroll;
  scroll-behavior: smooth;
  background: rgb(245, 245, 245);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 15%,
    rgba(223, 223, 223, 1) 85%
  );
}

.utopia-chat-message {
  max-width: 75%;
  padding-right: 3rem;
  position: relative;
  background-color: $utopia-white;
}

.utopia-chat-message-bot {
  margin-left: 10px;
}

.utopia-chat-message-bot.bot-arrow {
  margin-left: 0;
}

.utopia-chat-message-user,
.utopia-chat-message-user.user-arrow {
  margin-right: 0;
}

.utopia-chat-time {
  font-size: 0.75rem;
  position: absolute;
  bottom: 0;
  right: 0.3rem;
}

/* utilities */

.is-vertical-centered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.is-75 {
  max-width: 75%;
}

.ml-1-rem-2 {
  margin-left: 1.2rem !important;
}

.translucent {
  color: $utopia-medium-gray !important;
}

.triangle-left {
  @include chat_triangle('left', $brain-color);
}

.triangle-right {
  @include chat_triangle('right', $sesame-color);
}

.custom-grouped-select {
  margin-top: 0.45rem;
}

.custom-grouped-select.is-disabled div[class$='control'] {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.custom-grouped-select.is-disabled
  div[class$='control']
  div[class$='singleValue'] {
  color: #495057;
}

.custom-grouped-select.sm {
  font-size: 0.875rem;
  height: 31px;
}

.custom-grouped-select.sm div[css-1hb7zxy-IndicatorsContainer] {
  height: 31px;
}

.custom-grouped-select.sm div[class$='control'] {
  min-height: 31px;
}

.custom-grouped-select.sm div[class^='css'][class$='Input'] {
  padding: 0;
}

.custom-grouped-select.sm div[class$='indicatorContainer'] {
  padding: 0 3px;
}

.custom-grouped-select
  div[class$='menu']
  div:first-child
  > div[class$='group'] {
  display: none;
}

.custom-grouped-select
  div[class$='menu']
  div:nth-child(2)
  > div[class$='group'] {
  height: 1px;
  overflow: hidden;
  border-bottom: 1px solid #495057;
}

.video-thumbnail-button {
  max-height: 10rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.4s;
  position: relative;
  max-height: 120px;
}

.video-thubnail-button-icon {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $utopia-gray;
  transition: color 0.4s;
}

.video-thumbnail-button:hover,
.video-thumbnail-button:focus {
  opacity: 1;
}

.video-thumbnail-button:hover .video-thubnail-button-icon,
.video-thumbnail-button:focus .video-thubnail-button-icon {
  color: $utopia-white;
}

/* asr */

.transcriptions-wrapper {
  max-height: 500px;
  overflow-y: scroll;
}

.transcript-time {
  font-size: 0.9rem;
}

/* voice generator */

#voice-generator-statistics span {
  font-size: 0.82rem;
}

#voice-generator-statistics .badge {
  font-weight: 500;
}

/* labeling */

#labeling-form-container {
  position: relative;
}

#labeling-form-handler {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 9999;
  opacity: 0.4;
  font-size: 1.25rem;
  background-color: $utopia-dark;
  border-radius: 0.25rem;
  color: $utopia-light-gray;
  transition: opacity 0.5s ease;
}

#labeling-form-handler:hover {
  opacity: 0.9;
}

#labeling-rate-buttons .btn {
  font-size: 0.75rem;
}

/* labeling - wavesurfer */

#waveform wave wave {
  background-color: rgba(90, 98, 104, 0.25);
}

#waveform wave wave::before {
  content: '\25B6';
  position: absolute;
  right: 2px;
  top: -2px;
  color: $loquista-color;
  font-size: 1.15rem;
  padding-top: 2px;
}

region.wavesurfer-region.selected {
  background-color: rgba(235, 103, 28, 0.5) !important;
}

region.wavesurfer-region.selected .wavesurfer-handle-start {
  background-color: rgba(235, 103, 28, 0.8) !important;
}

region.wavesurfer-region.selected .wavesurfer-handle-end {
  background-color: rgba(235, 103, 28, 0.8) !important;
}

region.wavesurfer-region[key]:before {
  content: attr(key);
  margin-left: 3px;
  font-size: 0.6rem;
  padding: 3px 5px 2px 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.75);
  color: $utopia-white;
  opacity: 0.4;
  transition: opacity 0.3s ease;
}

region.wavesurfer-region[key]:hover::before {
  opacity: 1;
}

/* menu-toggle */
#utopia-menu-toggle {
  cursor: pointer;
  position: absolute;
  width: 40px;
  top: -48px;
  left: -8px;
  font-size: 1.35rem;
  opacity: 0.1;
  transition: opacity 0.4s ease, left 0.4s ease;
}

#utopia-menu-toggle:hover {
  left: -4px;
  opacity: 0.8;
}

/* face-replacement */

.face-replacement-row {
    padding: 1em 0;
}

/* face-movement */

.face-movement-row {
    padding: 1em 0;
}


/* media queries */

@include media-breakpoint-down(md) {
  .utopia-menu {
    display: none;
  }
  #utopia-menu-toggle {
    display: none;
  }
}

@include media-breakpoint-up(xs) {
  .utopia-home-image-wrap > img {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-up(sm) {
  .utopia-home-image-wrap > img {
    width: 80%;
    height: auto;
  }
}

@include media-breakpoint-up(md) {
  p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .utopia-nav {
    height: $header-height;
  }
  .utopia-main {
    min-height: $content-height;
  }
  .utopia-main > .row {
    min-height: $content-height;
  }
  .utopia-home-title {
    padding: 1.75rem 0 0.25rem;
    font-weight: 800;
  }
  .utopia-home-subtitle {
    color: $utopia-dark-gray;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .utopia-home-image-wrap > img {
    width: 100%;
    height: auto;
  }
  .utopia-home-features {
    margin-left: -2rem;
    @include home_features(20%, 0.9rem);
  }

  /*
    .utopia-landing-page>.row,
    .utopia-landing-page>.row>.p-0 {
        height: 100%;
    }
    */

  #utopia-landing-page-logo {
    min-height: $content-height;
  }

  .utopia-landing-page {
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /*
    #utopia-landing-page-logo.utopia-landing-page-panel img,
    #utopia-landing-page-logo.utopia-landing-page-panel.shrunk img
     {
        margin: 2rem auto;
        width: 75%;
        height: auto;
    }
    */
  .utopia-footer {
    height: $footer-height;
    background-color: $utopia-light-gray;
  }
  .utopia-products-cards img {
    height: 60%;
  }
}

@include media-breakpoint-up(lg) {
  .utopia-header {
    display: block;
  }
  .utopia-nav {
    display: none;
  }
  .utopia-home-features {
    @include home_features(20%, 0.95rem);
  }
}

@include media-breakpoint-up(xl) {
  .utopia-home-features {
    @include home_features(10%, 1rem);
  }
}
