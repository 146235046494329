@import 'colors';
$enable-gradients: true;
$theme-colors: ( 
	'primary': $utopia-dark, 
	'light': $utopia-light-gray, 
	'danger': firebrick, 
	'loquista': $loquista-color, 
	'sesame': $sesame-color, 
	'vision': $vision-color,
	'theia': $theia-color,
	'medium': $utopia-medium-gray, 
	'brain': $brain-color,
	'minerva': $minerva-color, 
	'flow': $flow-color, );
@import '~bootstrap/scss/bootstrap';