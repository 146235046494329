// black & white colors
$utopia-white: #ffffff;
$utopia-light-gray: #f5f5f5;
$utopia-gray: #eeeeee;
$utopia-medium-gray: #dfdfdf;
$utopia-dark: #8c8f91;
$utopia-dark-gray: #737373;
$utopia-black-gray: #626262;
$utopia-black: #000000;

// section-colors
$utopia-color: #647c87;
$loquista-color: #eb671c;
$sesame-color: #3b8b93;
$vision-color: #945151;
$theia-color: #945151;
$brain-color: #453F8C;
$minerva-color: #453F8C;
$flow-color: #C44040;

:export {
	loquista-color: $loquista-color;
}