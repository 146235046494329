@import 'colors';
@import 'sizes';

@mixin section_color ($section) {
	$color: $utopia-black;
	
	@if $section == 'loquista' {
		$color: $loquista-color;
	} @else if $section == 'sesame' {
		$color: $sesame-color;
	} @else if $section == 'vision' {
		$color: $vision-color;
	} @else if $section == 'theia' {
		$color: $theia-color;
	} @else if $section == 'brain' {
		$color: $brain-color;
	} @else if $section == 'minerva' {
		$color: $minerva-color;
	} @else if $section == 'flow' {
		$color: $flow-color;
	}@else {
		$color: $utopia-black;
	}

	color: $color;
}

@mixin chat_triangle ($direction, $color) {
	$bw: none;
	$bc: none;

	@if $direction == 'left' {
		$bw: 6px 10px 6px 0;
		$bc: transparent $color transparent transparent;
	} @else if $direction == 'right' {
		$bw: 6px 0 6px 10px;
		$bc: transparent transparent transparent $color;
	}

	display: inline-block;
  width: 0;
  height: 0;
	border-style: solid;
	border-width: $bw;
	border-color: $bc;
}

@mixin home_features ($mt, $fs) {
	margin-top: $mt;
	font-size: $fs;
}

@mixin menu_link ($color, $bg_color) {
	color: $color;
	background-color: $bg_color;
}